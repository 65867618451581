<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Keyboard shortcuts</h3>

	<p>Note that all keyboard shortcuts require that you hold down the ⌘ (COMMAND-MacOS) or ALT (Windows) key <i>and</i> the ⇧ (SHIFT) key while tapping the shortcut key.</p>

	<ul>
		<li><img srcset="/docimages/keyboard_shortcuts-3.png 6x" class="k-help-img float-right">When viewing a framework {{comp_noun}} in the <span v-html="link('treeview', '“tree view”')"></span> or <span v-html="link('tileview', '“tile view”')"></span>:
		<ul class="k-no-clear">
			<li><b>⌘/ALT + ⇧ + <v-icon small style="margin:-3px 2px 0 1px;" color="#222">fas fa-arrow-circle-up</v-icon>:</b> (up arrow) view the previous item in the framework (i.e. move up in the framework hierarchy)</li>
			<li><b>⌘/ALT + ⇧ + <v-icon small style="margin:-3px 2px 0 1px;" color="#222">fas fa-arrow-circle-down</v-icon>:</b> (down arrow) view the next item in the framework (i.e. move down in the framework hierarchy) <ul>
				<li class="my-4"><i><b>NOTE:</b> The up/down arrow shortcuts are invaluable for quickly reviewing a long series of items.</i></li>
			</ul> </li>
			<li><b>⌘/ALT + ⇧ + O</b>: (the letter “oh”) Add a new <span v-html="link('add_comments', 'comment')"></span> to the item (you must be signed in to <span v-html="app_name"/> in order to add comments)</li>
			<li><b>⌘/ALT + ⇧ + E</b>: <span v-html="link('edit_item', 'Edit')"></span> the item (you must be signed in to <span v-html="app_name"/>, and have <span v-html="link('roles_framework', 'editing rights')"></span> to the framework, in order to edit items)</li>
		</ul></li>

		<li class="mt-5"><img srcset="/docimages/keyboard_shortcuts-1.png 6x" class="k-help-img float-right mb-5">When viewing <span v-html="link('search', 'search results')"></span>:
		<ul class="k-no-clear">
			<li><b>⌘/ALT + ⇧ + <v-icon small style="margin:-3px 2px 0 1px;" color="#222">fas fa-arrow-circle-left</v-icon>:</b> (left arrow) view the previous item in the search results</li>
			<li><b>⌘/ALT + ⇧ + <v-icon small style="margin:-3px 2px 0 1px;" color="#222">fas fa-arrow-circle-right</v-icon>:</b> (right arrow) view the next item in the search results</li>
		</ul></li>

		<li><img srcset="/docimages/keyboard_shortcuts-2.png 6x" class="k-help-img float-right">When <span v-html="link('edit_item', 'editing')"></span> an item (or a document):
		<ul class="k-no-clear">
			<li><b>⌘/ALT + ⇧ + S</b>: Save changes to the item</li>
			<li><b>⌘/ALT + ⇧ + D</b>: Save changes to the item, then close the item editor</li>
			<li><b>⌘/ALT + ⇧ + 9</b>: Create a new child of the currently-selected item</li>
			<li><b>⌘/ALT + ⇧ + 0 (zero)</b>: Create a new sibling of the currently-selected item</li>
			<li><b>⌘/ALT + ⇧ + C</b>: Open the <span v-html="link('copy_item', 'Copy child items from this or another framework”')"></span> interface</li>
			<li><b>⌘/ALT + ⇧ + I</b>: Open the <span v-html="link('batch_add', '“Batch import child items from text input”')"></span> interface</li>
			<li><b>⌘/ALT + ⇧ + . (period)</b>: Toggle <span v-html="link('move_item', '“move mode”')"></span> on and off.</li>
		</ul></li>

	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	